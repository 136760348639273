import SearchIcon from '@mui/icons-material/Search';
import { Box, Grid, InputAdornment, Typography } from '@mui/material';
import CustomInput from 'components/CustomInput/customInput';
import { Loader } from 'components/loader';
import debouce from 'lodash.debounce';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getAllListData } from 'services/CommonServices';
import { RESUME_ENDPOINT } from 'utils/Constant';
import {
  MatchedProfileInfo,
  MatchedProfileParams,
  MatchedProfileResponse,
} from 'utils/type';

type Props = {
  setSelectCandidate: (src: string) => void;
  resumeId: string;
};
type cardProps = {
  setSelectCandidate: (src: string) => void;
  info: MatchedProfileInfo;
};
const CardDetails: React.FC<cardProps> = (props: cardProps) => {
  const { setSelectCandidate, info } = props;
  return (
    <Box className="card-info ">
      <Grid container spacing={2}>
        <Grid item md={2}>
          <span className="title">Name</span>
        </Grid>
        <Grid item md={10}>
          <span className="Value red-color">
            {info?.firstName} {info?.middleName} {info?.lastName}
          </span>
        </Grid>

        <Grid item md={2}>
          <span className="title">Job Title</span>
        </Grid>
        <Grid item md={10}>
          <span className="Value"> {info?.jobTitle || '-'}</span>
        </Grid>

        <Grid item md={2}>
          <span className="title">Email</span>
        </Grid>
        <Grid item md={10}>
          <span className="Value">{info?.email || '-'}</span>
        </Grid>

        <Grid item md={2}>
          <span className="title">Phone</span>
        </Grid>
        <Grid item md={4}>
          <span className="Value">{info?.mobileNumber}</span>
        </Grid>
        <Grid item md={6} className="d-flex-jae g-7">
          <Typography component={'div'} className="view-resume">
            View Resume
          </Typography>
          <Typography
            component={'div'}
            className="btn-update-resume"
            onClick={() => setSelectCandidate(info.id)}>
            Update Resume
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
export const CandidateList: React.FC<Props> = (props: Props) => {
  const { setSelectCandidate, resumeId } = props;

  const [hasMoreResults, setHasMoreResults] = React.useState(false);
  const [getMoreLoader, setGetMoreLoader] = React.useState(false);
  const [loader, setLoader] = React.useState<boolean>(false);
  const [matchedProfile, setMatchedProfile] =
    React.useState<MatchedProfileResponse | null>();
  const [params, setParams] = React.useState<MatchedProfileParams>({
    order: 'asc',
    resumeId: '',
    search: '',
    skip: 0,
    sortby: '',
    take: 50,
  });

  const handleChange = React.useCallback(
    (e: HTMLInputElement | any) => {
      setLoader(true);
      setParams((pre) => ({
        ...pre,
        search: e.target.value,
        skip: 0,
        take: 50,
      }));
    },
    [setParams],
  );
  const debouncedResults = React.useMemo(() => {
    return debouce(handleChange, 1000);
  }, [handleChange]);

  const getMatchedProfile = React.useCallback(() => {
    const queryString = new URLSearchParams(params as any).toString();
    getAllListData(
      `${RESUME_ENDPOINT.ResumeValidationMatchingProfile}/${resumeId}?${queryString}`,
      true,
    )
      .then((resp: MatchedProfileResponse) => {
        setLoader(false);
        setGetMoreLoader(false);
        if (params.skip === 0) {
          setMatchedProfile(resp);
        } else {
          setMatchedProfile((pre) => ({
            ...pre,
            data: [...pre.data, ...resp.data],
          }));
        }
        if (
          matchedProfile.data &&
          matchedProfile.data.length < Number(resp.total)
        ) {
          setHasMoreResults(true);
        } else {
          setHasMoreResults(false);
        }
      })
      .catch(() => {
        setLoader(false);
        setGetMoreLoader(false);
        setHasMoreResults(false);
      });
  }, [matchedProfile?.data, params, resumeId]);

  React.useEffect(() => {
    getMatchedProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  console.warn({ params });

  return (
    <Typography component={'div'}>
      <Typography
        component={'div'}
        p={2}
        pt={0}
        pl={0}
        className="d-flex-a flex-sb ">
        <Typography
          component={'div'}
          className="red-color resume-tab-header highlight">
          {'Select Candidate'}
        </Typography>
      </Typography>
      <Typography component={'div'} className="pb-12">
        <Typography component={'span'} className="resume-search-header plr-14">
          Candidate Search
        </Typography>
        <CustomInput
          className="custom-search"
          placeHolder="Search by Name, Phone number, Email"
          onChange={debouncedResults}
          inputProps={{
            endAdornment: (
              <InputAdornment position="end" className="icon-postition">
                <SearchIcon className="icon-size" />
              </InputAdornment>
            ),
          }}
        />
      </Typography>

      <Typography component={'div'} className="list-header plr-14">
        Matched Profiles{' '}
        <span className="red-color count">{matchedProfile?.total}</span>
      </Typography>

      <Typography
        component={'div'}
        className="body-hight body-hight3"
        id="matching-id">
        {loader ? (
          <Loader
            pageCenterLoadingHide={true}
            className={'d-flex-ja w-100 h-100'}
          />
        ) : matchedProfile?.data.length ? (
          <InfiniteScroll
            dataLength={matchedProfile.data.length}
            next={() => {
              if (
                matchedProfile.data.length < matchedProfile.total &&
                !getMoreLoader
              ) {
                setGetMoreLoader(true);
                setParams({
                  ...params,
                  skip: params.take + params.skip,
                });
              }
            }}
            hasMore={hasMoreResults}
            loader={
              getMoreLoader && (
                <div className="lds-ellipsis">
                  <div />
                  <div />
                  <div />
                  <div />
                </div>
              )
            }
            scrollableTarget={'matching-id'}>
            {matchedProfile?.data.map((e) => (
              <Typography component={'div'} className="pt-12" key={e.id}>
                <CardDetails setSelectCandidate={setSelectCandidate} info={e} />
              </Typography>
            ))}
          </InfiniteScroll>
        ) : (
          <Typography
            component={'div'}
            className={'d-flex-ja w-100 h-100 red-color'}>
            No Profile Matched
          </Typography>
        )}
      </Typography>

      <Typography component={'div'} className="footer-info">
        <span className="other"> (or) </span>
        <Typography
          component={'div'}
          className="resume-btn-footer"
          onClick={() => setSelectCandidate('new')}>
          Create New
        </Typography>
      </Typography>
    </Typography>
  );
};
