import { Grid, Typography } from '@mui/material';
import CustomInput from 'components/CustomInput/customInput';
import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { GeneralDetails } from 'types';

type Props = {
  control: UseFormReturn<GeneralDetails>;
};
export const ProfileInfo: React.FC<Props> = (props: Props) => {
  const { control: formControl } = props;
  const { control, formState } = formControl;
  return (
    <Typography component={'div'}>
      <Grid container spacing={1}>
        <Grid item md={4} sm={12}>
          <Typography component={'label'} className="input-title pb-2">
            First Name
          </Typography>
          <Typography component={'div'}>
            <Controller
              control={control}
              defaultValue=""
              name="firstName"
              render={({ field: { onChange, value, name, ref } }) => {
                return (
                  <CustomInput
                    placeHolder=""
                    value={value}
                    name={name}
                    className="custom-Input"
                    ref={ref}
                    onChange={(e: any) => {
                      onChange(e);
                    }}
                    error={formState.errors?.firstName?.message}
                  />
                );
              }}
            />
          </Typography>
        </Grid>
        <Grid item md={4} sm={12}>
          <Typography component={'label'} className="input-title pb-2">
            Last Name
          </Typography>
          <Typography component={'div'}>
            <Controller
              control={control}
              defaultValue=""
              name="lastName"
              render={({ field: { onChange, value, name, ref } }) => {
                return (
                  <CustomInput
                    placeHolder=""
                    value={value}
                    name={name}
                    className="custom-Input"
                    ref={ref}
                    onChange={(e: any) => {
                      onChange(e);
                    }}
                    error={formState.errors?.lastName?.message}
                  />
                );
              }}
            />
          </Typography>
        </Grid>
        <Grid item md={4} sm={12}>
          <Typography component={'label'} className="input-title pb-2">
            Primary Email
          </Typography>
          <Typography component={'div'}>
            <Controller
              control={control}
              defaultValue=""
              name="primaryEmail"
              render={({ field: { onChange, value, name, ref } }) => {
                return (
                  <CustomInput
                    placeHolder=""
                    value={value}
                    name={name}
                    className="custom-Input"
                    ref={ref}
                    onChange={(e: any) => {
                      onChange(e);
                    }}
                    error={formState.errors?.primaryEmail?.message}
                  />
                );
              }}
            />
          </Typography>
        </Grid>
        <Grid item md={4} sm={12}>
          <Typography component={'label'} className="input-title pb-2">
            City
          </Typography>
          <Typography component={'div'}>
            <Controller
              control={control}
              defaultValue=""
              name="city"
              render={({ field: { onChange, value, name, ref } }) => {
                return (
                  <CustomInput
                    placeHolder=""
                    value={value}
                    name={name}
                    className="custom-Input"
                    ref={ref}
                    onChange={(e: any) => {
                      onChange(e);
                    }}
                    error={formState.errors?.city?.message}
                  />
                );
              }}
            />
          </Typography>
        </Grid>
        <Grid item md={4} sm={12}>
          <Typography component={'label'} className="input-title pb-2">
            State
          </Typography>
          <Typography component={'div'}>
            <Controller
              control={control}
              defaultValue=""
              name="state"
              render={({ field: { onChange, value, name, ref } }) => {
                return (
                  <CustomInput
                    placeHolder=""
                    value={value}
                    name={name}
                    className="custom-Input"
                    ref={ref}
                    onChange={(e: any) => {
                      onChange(e);
                    }}
                    error={formState.errors?.state?.message}
                  />
                );
              }}
            />
          </Typography>
        </Grid>
        <Grid item md={4} sm={12}>
          <Typography component={'label'} className="input-title pb-2">
            Job Title
          </Typography>
          <Typography component={'div'}>
            <Controller
              control={control}
              defaultValue=""
              name="jobTitle"
              render={({ field: { onChange, value, name, ref } }) => {
                return (
                  <CustomInput
                    placeHolder=""
                    value={value}
                    name={name}
                    className="custom-Input"
                    ref={ref}
                    onChange={(e: any) => {
                      onChange(e);
                    }}
                    error={formState.errors?.jobTitle?.message}
                  />
                );
              }}
            />
          </Typography>
        </Grid>

        <Grid item md={4} sm={12}>
          <Typography component={'label'} className="input-title pb-2">
            Phone Number
          </Typography>
          <Typography component={'div'}>
            <Controller
              control={control}
              defaultValue=""
              name="phoneNumber"
              render={({ field: { onChange, value, name, ref } }) => {
                return (
                  <CustomInput
                    placeHolder=""
                    value={value}
                    name={name}
                    className="custom-Input"
                    ref={ref}
                    onChange={(e: any) => {
                      onChange(e);
                    }}
                    error={formState.errors?.phoneNumber?.message}
                  />
                );
              }}
            />
          </Typography>
        </Grid>

        <Grid item md={4} sm={12}>
          <Typography component={'label'} className="input-title pb-2">
            Linkedin URL
          </Typography>
          <Typography component={'div'}>
            <Controller
              control={control}
              defaultValue=""
              name="linkedInUrl"
              render={({ field: { onChange, value, name, ref } }) => {
                return (
                  <CustomInput
                    placeHolder=""
                    value={value}
                    name={name}
                    className="custom-Input"
                    ref={ref}
                    onChange={(e: any) => {
                      onChange(e);
                    }}
                    error={formState.errors?.linkedInUrl?.message}
                  />
                );
              }}
            />
          </Typography>
        </Grid>

        <Grid item md={4} sm={12}>
          <Typography component={'label'} className="input-title pb-2">
            Source
          </Typography>
          <Typography component={'div'}>
            <Controller
              control={control}
              defaultValue=""
              name="source"
              render={({ field: { onChange, value, name, ref } }) => {
                return (
                  <CustomInput
                    placeHolder=""
                    value={value}
                    name={name}
                    className="custom-Input"
                    ref={ref}
                    onChange={(e: any) => {
                      onChange(e);
                    }}
                    error={formState.errors?.source?.message}
                  />
                );
              }}
            />
          </Typography>
        </Grid>

        <Grid item md={4} sm={12}>
          <Typography component={'label'} className="input-title pb-2">
            Work Auth
          </Typography>
          <Typography component={'div'}>
            <Controller
              control={control}
              defaultValue=""
              name="workAuth"
              render={({ field: { onChange, value, name, ref } }) => {
                return (
                  <CustomInput
                    placeHolder=""
                    value={value}
                    name={name}
                    className="custom-Input"
                    ref={ref}
                    onChange={(e: any) => {
                      onChange(e);
                    }}
                    error={formState.errors?.workAuth?.message}
                  />
                );
              }}
            />
          </Typography>
        </Grid>
      </Grid>
    </Typography>
  );
};
