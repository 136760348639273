import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, DialogProps, IconButton, Modal } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CloseJob from 'assets/images/closejob.png';
import BackIcon from 'assets/images/larrow.svg';
import CommonUploadBtn from 'components/CommonUploadBtn';
import CustomSelect from 'components/CustomSelect/customSelect';
import UpArrowSvgIcon from 'components/SvgAsCode/upArrow';
import { ViewJobConstants } from 'config';
import AddAndEditJob from 'pages/common/AddAndEditJob';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  addNewData,
  getAllListData,
  updateData,
} from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { USER_RELOAD } from 'store/types/UserTypes';
import { JOBCLOSINGSTATUS } from 'utils/ArrayConstants';
import { alertOptions } from 'utils/Config';
import { MASTER_ENDPOINT, RESUME_ENDPOINT } from 'utils/Constant';

import {
  childProps,
  ResumeValidationTable,
} from '../ResumeValidation/ResumeValidation';
import UploadResumeModal from '../UploadResumeModal';

const style = {
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  flexDirection: 'column',
  left: '50%',
  p: 4,
  position: 'absolute' as const,
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: 780,
};

export const Resumes: React.FC<any> = () => {
  const childRef = useRef<childProps>(null);
  const [opening, setopening] = useState<boolean>(false);
  const [SuccessUPRESLoader, setSuccessUPRESLoader] = useState<boolean>(false);
  const [ResumeuploadLoader, setResumeuploadLoader] = useState<boolean>(false);
  const [opend, setOpend] = useState<boolean>(false);
  const [jobData, setJobData] = useState<any>({});

  const [open1, setOpen1] = useState<boolean>(false);
  const [jobClosingStatus, setJobClosingStatus] = useState<string>('filled');
  const [scroll, setScroll] = useState<DialogProps['scroll']>('paper');
  const [JobListingData, setJobListingData] = useState<any>([
    { label: 'Select a source for the resume', value: undefined },
  ]);

  const { jobId, jobtab } = useParams();
  const navigation: any = useNavigate();
  const [workStatus, setWorkStatus] = useState<any>([]);
  const history = useNavigate();

  const { reload, userInfo }: any = useAppSelector(
    (store: any) => store.userLogin,
  );

  async function ListApiCall() {
    setResumeuploadLoader(true);
    try {
      const resp = await getAllListData(
        `${RESUME_ENDPOINT.ResumeValidationsources}`,
      );
      console.log(resp, 'resp');
      if (resp && resp.data) {
        setJobListingData(resp.data);
      }
    } catch (error) {
      console.log(error, 'error');
    } finally {
      setResumeuploadLoader(false);
    }
  }

  function CloseFn() {
    setopening((p) => !p);
    setSuccessUPRESLoader(false);
  }

  const handleOpendelete = () => {
    setOpend(true);
  };
  const handleClosedelete = () => setOpend(false);
  const goBackFun = () => {
    navigation(-1);
  };

  const getWorkStatus = () => {
    getAllListData(MASTER_ENDPOINT.WorkStatus + '?status=true').then(
      (resp: any) => {
        setWorkStatus(resp?.data);
      },
    );
  };
  const handleClickOpen = (scrollType: DialogProps['scroll']) => () => {
    setOpen1(true);
    setScroll(scrollType);
  };
  const handleClose1 = () => {
    setOpen1(false);
  };

  const getJobData = async (id: string) => {
    await getAllListData(`${MASTER_ENDPOINT.Jobs}?id=${id}`)
      .then((res) => {
        setJobData(res?.data?.[0]);
        if (res?.data?.[0]?.jobSkills) {
          let sName = '';
          let primarySkill = '';
          res?.data?.[0]?.jobSkills.map((item: any) => {
            sName += item.skill.name + ',';
            primarySkill += item.skill.primary ? item.skill.name + ',' : '';
          });
          if (sName) {
            sName = sName.replace(/,\s*$/, '');
          }
          if (primarySkill) {
            primarySkill = primarySkill.replace(/,\s*$/, '');
          }
        }
      })
      .catch((err: any) => {})
      .finally(() => {});
  };

  useEffect(() => {
    ListApiCall();
    getWorkStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    getJobData(jobId);
  }, [jobId, reload]);

  const onSubmit = (data: any) => {
    const formData = new FormData();
    const jobsArr = [];
    const wsIds = [];
    jobData?.jobWorkStatus?.map((e) => {
      wsIds.push(e.workStatus.id);
    });
    jobData.jobSkills.map((item: any) => {
      jobsArr.push({
        preffered: item.preffered,
        required: item.required,
        rowId: item.id,
        skillId: item.skillId,
      });
    });

    formData.append('customerId', jobData?.customerId);
    formData.append('certificates', jobData?.certificates);
    formData.append('organizationId', userInfo.organizationId);
    formData.append('city', jobData?.city);
    formData.append('education', jobData?.education);
    formData.append('educationNotes', jobData?.educationNotes);
    formData.append('interviewType', jobData?.interviewType);
    formData.append('jobDurationFrom', jobData?.jobDurationFrom);
    formData.append('jobDurationTo', jobData?.jobDurationTo);
    formData.append('jobTitle', jobData?.jobTitle);
    formData.append('jobsSkills', JSON.stringify(jobsArr));
    formData.append('notes', jobData?.notes);
    formData.append('payRangeFrom', jobData?.payRangeFrom);
    formData.append('payRangeTo', jobData?.payRangeTo);
    formData.append('zip', jobData?.zip);
    formData.append('workType', jobData?.workType);
    formData.append('jobWorkStatusIds', JSON.stringify(wsIds));
    formData.append('state', jobData?.state);
    formData.append('startDate', jobData?.startDate);
    formData.append('projectRole', jobData?.projectRole);
    formData.append('positionType', jobData?.positionType);
    formData.append('payRateType', jobData?.payRateType);
    formData.append('jobDescription', jobData?.jobDescription);
    formData.append(
      'salaryDesired',
      jobData['salaryDesired'] ? jobData['salaryDesired'] : 0,
    );
    // formData.append('position', jobData?.position);
    // formData.append('status', 'closed');
    formData.append('status', jobClosingStatus);
    formData.append('publish', jobData?.publish);
    if (jobData.id) {
      formData.append('removeRowId', JSON.stringify([]));
      formData.append('removeFileId', JSON.stringify([]));
      updateData(jobData.id, formData, MASTER_ENDPOINT.Jobs, true).then(
        (resp: any) => {
          dispatch({
            type: USER_RELOAD,
          });
          handleClosedelete();
          navigation('/recruiter');
        },
      );
    }
  };
  async function ResumeSubmit(sourceId: any, Files: any) {
    const formData = new FormData();
    formData.append('sourceId', sourceId);
    formData.append('file', Files && Files[0]);
    formData.append('jobPostingId', jobId);
    formData.append('type', 'jobResume');
    // console.log(formData, 'formData');
    setResumeuploadLoader(true);
    setSuccessUPRESLoader(false);

    try {
      const resp = await addNewData(
        formData,
        RESUME_ENDPOINT.ResumeValidation,
        {},
        false,
      );
      // console.log(resp, 'resp');
      if (resp) {
        setSuccessUPRESLoader(true);
        setResumeuploadLoader(false);
      }
      childRef.current.getUpdatedResume();
    } catch (error) {
      setSuccessUPRESLoader(false);
      toast.error(error, alertOptions);
    } finally {
      setResumeuploadLoader(false);
      // console.log(SuccessUPRESLoader, 'successloader');
    }
  }
  return (
    <>
      <Typography component={'div'}>
        <Grid container>
          {
            <Grid item md={12} sm={12}>
              <Typography component={'div'} className="jobview-header d-flex-a">
                <Grid container>
                  <Grid
                    item
                    md={2}
                    sm={4}
                    className="d-flex-a"
                    onClick={goBackFun}>
                    <img
                      src={BackIcon}
                      alt="Icon"
                      style={{ cursor: 'pointer', marginLeft: '21px' }}
                    />
                    <Typography className="back">Back</Typography>
                  </Grid>
                  <Grid
                    item
                    md={10}
                    sm={8}
                    className="d-flex-a flex-sb"
                    sx={{ paddingRight: '32px !important' }}>
                    <Grid container gap={'51px'} sx={{ alignSelf: 'center' }}>
                      <Typography
                        className={`job-dis ${
                          jobtab !== ViewJobConstants.Resumes
                            ? 'job-dis-activ'
                            : ''
                        }`}
                        sx={{ cursor: 'pointer' }}
                        onClick={() =>
                          history(
                            `/${
                              userInfo.roleId === 5 ? 'sales' : 'recruiter'
                            }/job-post/view-job/${
                              ViewJobConstants.myPosting
                            }/${jobId}`,
                          )
                        }>
                        Job Descriptions
                      </Typography>
                      <Typography
                        className={`job-dis ${
                          jobtab === ViewJobConstants.Resumes
                            ? 'job-dis-activ'
                            : ''
                        }`}
                        sx={{ cursor: 'pointer' }}
                        onClick={() =>
                          history(
                            `/${
                              userInfo.roleId === 5 ? 'sales' : 'recruiter'
                            }/job-post/view-job/${
                              ViewJobConstants.Resumes
                            }/${jobId}`,
                          )
                        }>
                        Resumes
                      </Typography>
                    </Grid>
                    {location.pathname.includes('my-posting') && (
                      <Grid
                        item
                        md={2}
                        sm={4}
                        sx={{ marginRight: '20px !important' }}>
                        <Typography
                          component={'div'}
                          className="d-flex-a"
                          sx={{
                            gap: '20px',
                            justifyContent: 'flex-end',
                          }}>
                          {userInfo.roleId === 5 && (
                            <Button
                              className="edit-btn"
                              onClick={handleClickOpen('body')}>
                              Edit
                            </Button>
                          )}
                          {open1 && (
                            <AddAndEditJob
                              workStatusList={workStatus}
                              open={open1}
                              editId={jobData.id}
                              editRow={jobData}
                              scroll={scroll}
                              onClose={handleClose1}
                            />
                          )}
                          {userInfo?.roleId === 3 ? (
                            <Button
                              className="close-btn"
                              sx={{ minWidth: '100px' }}
                              onClick={handleOpendelete}>
                              Close Job
                            </Button>
                          ) : null}
                          <Modal
                            className="job-close-modal"
                            open={opend}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description">
                            <Box
                              sx={style}
                              className="confirmation-widget d-flex-ja">
                              <IconButton
                                aria-label="Close"
                                onClick={handleClosedelete}
                                sx={{
                                  position: 'absolute',
                                  right: 5,
                                  top: 5,
                                }}>
                                <CloseIcon />
                              </IconButton>
                              <img src={CloseJob} alt="delete" />
                              <Typography
                                sx={{
                                  fontSize: '24px !important',
                                  margin: '19px 0 28px 0',
                                }}>
                                Are you sure you want to close out this job?
                              </Typography>
                              <Grid
                                container
                                sx={{
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  marginBottom: '20px',
                                }}>
                                <Grid item md={6} sm={6}>
                                  <Typography component={'div'}>
                                    <label>Reason</label>
                                    <span className="errorText">{'*'}</span>
                                    <CustomSelect
                                      onChange={(e: any) => {
                                        setJobClosingStatus(e?.target?.value);
                                      }}
                                      value={jobClosingStatus}
                                      labelKey={'name'}
                                      valueKey={'id'}
                                      options={JOBCLOSINGSTATUS}
                                      placeHolder={''}
                                      className={'common-select'}
                                    />
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Typography
                                className="confirm-btn model-btn"
                                component={'div'}>
                                <Button
                                  className="no-btn"
                                  onClick={handleClosedelete}
                                  sx={{
                                    borderRadius: '8px',
                                    textTransform: 'capitalize',
                                  }}>
                                  No, Cancel
                                </Button>
                                <Button
                                  className="job-delete-btn"
                                  onClick={onSubmit}
                                  sx={{
                                    borderRadius: '8px',
                                    textTransform: 'capitalize',
                                  }}>
                                  YES, Close Out Job
                                </Button>
                              </Typography>
                            </Box>
                          </Modal>
                        </Typography>
                      </Grid>
                    )}

                    <CommonUploadBtn
                      onPress={CloseFn}
                      name={'Upload Resume'}
                      Icon={UpArrowSvgIcon}
                      // width="130px"
                      width="148px"
                      svgStyle={{
                        height: '15px',
                        width: '13px',
                      }}
                    />

                    <UploadResumeModal
                      open={opening}
                      ClickFn={CloseFn}
                      SubmitFn={ResumeSubmit}
                      Success={SuccessUPRESLoader}
                      Loader={ResumeuploadLoader}
                      JobListingData={JobListingData}
                      JobTitle={
                        jobData && jobData.jobTitle ? jobData.jobTitle : ''
                      }
                      Customer={
                        jobData && jobData.customer && jobData.customer.name
                          ? jobData.customer.name
                          : ''
                      }
                      headerShown
                    />
                  </Grid>
                </Grid>
              </Typography>
            </Grid>
          }
        </Grid>
      </Typography>
      <ResumeValidationTable
        {...{
          jobData: jobData,
          jobPostingId: jobId ? jobId : undefined,
          placeHolder: 'Candidate Name',
          resumes: true,
          type: 'jobResume',
        }}
        ref={childRef}
      />
    </>
  );
};
function dispatch(arg0: { type: any }) {
  throw new Error('Function not implemented.');
}
