/* eslint-disable react/jsx-no-useless-fragment */
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Typography } from '@mui/material';
import CustomButton from 'components/CustomButton/customButton';
import { Loader } from 'components/loader';
import React from 'react';
import { useForm } from 'react-hook-form';
import { getAllListData } from 'services/CommonServices';
import {
  CreateCandidateApiResponse,
  CreateCandidateparserData,
  GeneralDetails,
} from 'types';
import { RESUME_ENDPOINT } from 'utils/Constant';
import { AddGeneralInfoSchema } from 'utils/ValidatorSchema';

import { AddNotes } from '../Notes/AddNotes';
import { AccordionView } from './Accordion';

const Datasd = {
  city: 'Dallas',
  countryCode: '13',
  dob: '2023-02-22',
  education: [
    {
      college: 'SAMPLE',
      degree: 'SAMPLE',
      modeOfEducation: 'SAMPLE',
      percentageMarksOrGrade: '12.34',
      qualification: 'SAMPLE',
      university: 'SAMPLE',
      yearOfPassing: '13',
    },
  ],
  experienceYears: '13',
  firstName: 'Jane',
  gender: 'SAMPLE',
  jobTitle: 'SAMPLE',
  lastName: 'Doe',
  linkedinUrl: 'https://example.com',
  marriageStatus: 'SAMPLE',
  middleName: 'J',
  nativeLocation: 'Dallas',
  noticePeriod: '13',
  phoneNumber: '9898989898',
  primaryEmail: 'Myemail@domain.com',
  projects: [
    {
      companyName: 'SAMPLE',
      designation: 'SAMPLE',
      fromDate: '2023-02-22',
      isCurrentlyWorking: true,
      location: 'Dallas',
      projectDescription: 'This is a description message',
      projectTitle: 'SAMPLE',
      toDate: null,
    },
    {
      companyName: 'SAMPLE',
      designation: 'SAMPLE',
      fromDate: '2023-02-22',
      isCurrentlyWorking: false,
      location: 'Dallas',
      projectDescription: 'This is a description message',
      projectTitle: 'SAMPLE',
      toDate: '2023-02-22',
    },
  ],
  skill: ['SAMPLE'],
  state: 'Texas',
} as const;

export const accordionTypes = [
  {
    name: 'Personal Information',
    type: 'profileInfo',
  },
  {
    name: 'Experience Information',
    type: 'experienceInfo',
  },
  {
    name: 'Education Information',
    type: 'educationInfo',
  },
  {
    name: 'Skills',
    type: 'skils',
  },
];
export const CreateCandidate: React.FC<
  { close?: any; resumeId: string } | any
> = (props) => {
  const { close, resumeId } = props;
  // const [shownNotes, setShownAddNotes] = React.useState(false);
  const [done, setdone] = React.useState(false);
  const [Response, setResponse] = React.useState<typeof Datasd>(Datasd);
  const [loading, setLoading] = React.useState(false);

  function ValueChecker(MyLocaldata: any, value: any) {
    if (MyLocaldata && MyLocaldata[value]) {
      return MyLocaldata[value];
    } else {
      return undefined;
    }
  }

  const getMatchedProfile = React.useCallback(() => {
    getAllListData(
      `${RESUME_ENDPOINT.ResumeValidationParsed}/${String(resumeId)}`,
      true,
    )
      .then((resp: CreateCandidateApiResponse) => {
        console.log(resp, resp.data.file, 'resp');

        if (resp && resp.data && resp.data.file && resp.data.file.parserData) {
          setResponse(Datasd);
          console.log(Response, 'Response');

          const MyLocaldata: CreateCandidateparserData =
            resp.data.file.parserData;
          setValue('firstName', ValueChecker(MyLocaldata, 'firstName'));
          setValue('lastName', ValueChecker(MyLocaldata, 'lastName'));
          setValue('primaryEmail', ValueChecker(MyLocaldata, 'primaryEmail'));
          setValue('city', ValueChecker(MyLocaldata, 'city'));
          setValue('state', ValueChecker(MyLocaldata, 'state'));
          setValue('jobTitle', ValueChecker(MyLocaldata, 'jobTitle'));
          setValue('phoneNumber', ValueChecker(MyLocaldata, 'phoneNumber'));
          setValue('linkedInUrl', ValueChecker(MyLocaldata, 'linkedinUrl'));
          setValue('source', ValueChecker(MyLocaldata, 'source'));
          setValue('workAuth', ValueChecker(MyLocaldata, 'workAuth'));
          console.log(ValueChecker(MyLocaldata, 'projects'), 'oiujiojio');

          if (ValueChecker(MyLocaldata, 'projects')) {
            console.log(ValueChecker(MyLocaldata, 'projects'), '11oiujiojio');
            setValue(
              'experience',
              ValueChecker(MyLocaldata, 'projects').map(
                (rt: any, idx: number) => {
                  return {
                    employer: ValueChecker(rt, 'companyName'),
                    fromDate: ValueChecker(rt, 'fromDate'),
                    jobTitle: ValueChecker(rt, 'designation'),
                    location: ValueChecker(rt, 'location'),
                    project: ValueChecker(rt, 'projectTitle'),
                    toDate: ValueChecker(rt, 'toDate'),
                  };
                },
              ),
            );
          }
          if (ValueChecker(MyLocaldata, 'education')) {
            console.log(ValueChecker(MyLocaldata, 'education'), 'edy55');

            setValue(
              'educations',
              ValueChecker(MyLocaldata, 'education').map((rt: any) => {
                return {
                  country: ValueChecker(rt, 'country'),
                  degree: ValueChecker(rt, 'degree'),
                  graduationDate: ValueChecker(rt, 'yearOfPassing'),
                  university: ValueChecker(rt, 'university'),
                };
              }),
            );
          }
          if (ValueChecker(MyLocaldata, 'skill')) {
            console.log(ValueChecker(MyLocaldata, 'skill'), '11oiujiojio');

            setValue(
              'skills',
              ValueChecker(MyLocaldata, 'skill').map((rt: any, idt: number) => {
                return {
                  id: idt,
                  name: rt ? rt : undefined,
                };
              }),
            );
          }
        }

        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resumeId]);

  React.useEffect(() => {
    setLoading(true);
    getMatchedProfile();
  }, [getMatchedProfile]);

  function handleDone() {
    setdone((p) => !p);
  }
  const formControl = useForm<GeneralDetails>({
    defaultValues: {
      city: '',
      educations: [],
      experience: [],
      firstName: '',
      jobTitle: '',
      lastName: '',
      linkedInUrl: '',
      notes: '',
      phoneNumber: '',
      primaryEmail: '',
      skills: [],
      source: '',
      state: '',
      workAuth: '',
    },
    reValidateMode: 'onChange',
    resolver: yupResolver(AddGeneralInfoSchema),
  });
  const { setValue } = formControl;
  const submit = (formData: GeneralDetails) => {
    console.warn(formData);
    setLoading(true);
  };
  return (
    <Typography component={'div'}>
      <>
        <Typography component={'div'} className="d-flex-a flex-sb plr-14 pb-4">
          <Typography
            component={'div'}
            className="red-color resume-tab-header highlight pl-0">
            {'General Details'}
          </Typography>
          {/* {!shownNotes && (
            <Typography
              component={'div'}
              className="btn-update-resume add-notes"
              onClick={() => setShownAddNotes(true)}>
              <span className="plus">+</span> Add Notes
            </Typography>
          )} */}
        </Typography>
        {loading ? (
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              height: '75vh',
              justifyContent: 'center',
            }}>
            <Loader
              pageCenterLoadingHide={true}
              className={'d-flex-ja w-100 h-100'}
            />
          </Box>
        ) : (
          <Box>
            <Typography
              component={'div'}
              sx={{ height: '75vh', overflow: 'auto' }}>
              <div
                style={{
                  opacity: done ? 0.12 : 1,
                  pointerEvents: done ? 'none' : 'auto',
                }}
                onClick={(eg: any) => {
                  if (done) {
                    eg.preventDefault();
                    return;
                  }
                }}>
                <AddNotes setValue={formControl.setValue} hideButton={true} />

                <form
                  onSubmit={formControl.handleSubmit(submit)}
                  id="generalForm">
                  {accordionTypes.map((e) => (
                    <AccordionView
                      key={e.type}
                      header={e.name}
                      type={e.type}
                      control={formControl}
                      closeAcc={done ? true : false}
                    />
                  ))}
                </form>
              </div>
              {done ? (
                <div className="reasoncontainer">
                  <div className="inputContainer">
                    <div className="label">Reason for accept / Reject</div>
                    <textarea
                      name=""
                      id=""
                      cols={30}
                      rows={30}
                      value={
                        'Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis tellus.'
                      }
                    />
                  </div>
                </div>
              ) : (
                <></>
              )}
            </Typography>
            {done ? (
              <div className="reasonBtnContainer">
                <div className="btn bt1" onClick={handleDone}>
                  cancel
                </div>
                <div className="btn bt2" onClick={close}>
                  Save
                </div>
              </div>
            ) : (
              <></>
            )}
            {done ? (
              <></>
            ) : (
              <Typography
                component={'div'}
                className="d-flex-jae"
                sx={{ marginTop: '20px' }}>
                <div
                  className="discardbtn"
                  onClick={handleDone}
                  style={{ marginRight: '26px' }}>
                  Discard
                </div>
                <CustomButton
                  onPress={(e: any) => {
                    e.preventDefault();
                    close && close();
                    console.warn('warning media');
                  }}
                  variant="contained"
                  type={'submit'}
                  isStyle={true}
                  name={'Submit'}
                  loading={loading}
                  form={'generalForm'}
                  className={'GeneralDetails'}
                />
              </Typography>
            )}
          </Box>
        )}
      </>
    </Typography>
  );
};
