import { Grid, Typography } from '@mui/material';
import CustomInput from 'components/CustomInput/customInput';
import React from 'react';
import { Controller, useFieldArray, UseFormReturn } from 'react-hook-form';
import { GeneralDetails, SkillType } from 'types';

import { SkillMenu } from './skilMenu';

type Props = {
  control: UseFormReturn<GeneralDetails>;
};
export const SkillsInfo: React.FC<Props> = (props: Props) => {
  const { control: formControl } = props;
  const { control, formState } = formControl;
  const id = 'button-popover';
  const [selectSkill, setSelectSkill] = React.useState<Array<SkillType>>([]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'skills',
  });

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const addSkills = () => {
    const removeIndex = [];
    fields.map((e, i) => {
      if (e.rowId) {
        removeIndex.push(i);
      }
    });
    remove(removeIndex); // Removed existing Skills
    append(selectSkill); // Add updated Skills
  };

  return (
    <Typography
      component={'div'}
      sx={{
        position: 'relative',
      }}>
      <Typography component={'div'} className="d-flex-jae pb-12">
        <Typography
          aria-describedby={id}
          onClick={handleClick}
          component={'button'}
          type={'button'}
          className="btn-update-resume add-notes skill">
          <span className="plus">+</span> Add SKill
        </Typography>
        {anchorEl && (
          <SkillMenu
            selectSkill={selectSkill}
            setSelectSkill={setSelectSkill}
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            id={id}
            addSkills={addSkills}
          />
        )}
      </Typography>
      <Grid container spacing={1}>
        {fields && fields.length
          ? fields.map((item, index) => (
              <Grid item md={4} sm={12} key={index}>
                <Typography component={'div'}>
                  <Controller
                    control={control}
                    defaultValue=""
                    name={`skills.${index}.name`}
                    render={({ field: { onChange, value, name, ref } }) => {
                      return (
                        <CustomInput
                          placeHolder=""
                          value={value}
                          name={name}
                          className="custom-Input"
                          ref={ref}
                          onChange={(e: any) => {
                            onChange(e);
                          }}
                          error={
                            formState.errors?.skills?.[index]?.name?.message
                          }
                        />
                      );
                    }}
                  />
                </Typography>
              </Grid>
            ))
          : null}
      </Grid>
    </Typography>
  );
};
