import { Box, FormHelperText, Typography } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React, { ReactElement } from 'react';

type DatePickerProps = {
  placeHolder?: string;
  onChange?: (src: any) => void;
  value?: any;
  error?: any;
  name?: string;
  minDate?: any;
  maxDate?: any;
  disabled?: boolean;
};

const DatePickerCom = (props: DatePickerProps): ReactElement => {
  const {
    placeHolder,
    onChange,
    value,
    error,
    name,
    minDate,
    maxDate,
    disabled = false,
  } = props;

  return (
    <Typography>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label="Custom input"
          value={value}
          minDate={minDate}
          maxDate={maxDate}
          onChange={onChange}
          disabled={disabled}
          renderInput={({ inputRef, inputProps, InputProps }) => (
            <Box
              sx={{
                '& .MuiInputAdornment-positionEnd': {
                  alignItems: 'center',
                  alignSelf: 'center',
                  justifyContent: 'center',
                },
                '& input': {
                  border: '0px !important',
                  borderRadius: '0px !important',
                  height: '44px !important',
                  width: '100% !important',
                },
                '& svg': {
                  color: 'grey !important',
                },
                alignItems: 'center',
                background: '#f3f3f3',
                display: 'flex',
              }}>
              {InputProps?.endAdornment}
              <input
                ref={inputRef}
                {...inputProps}
                name={name}
                placeholder={placeHolder}
                disabled
              />
            </Box>
          )}
        />
      </LocalizationProvider>
      {error && (
        <FormHelperText className="errorMessage">{error}</FormHelperText>
      )}
    </Typography>
  );
};

export default DatePickerCom;
